var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.edition && !_vm.$route.params.cmd && !_vm.$route.params.of_id),expression:"!edition && !$route.params.cmd && !$route.params.of_id"}],attrs:{"cols":"12","sm":4,"md":4}},[_c('listitemspage',{key:_vm.klist,attrs:{"selitem":_vm.selitem,"qSelect":_vm.Qselect,"qSelectall":_vm.Qselectall,"PageResponse":'mvms',"AllResponse":'allmvm',"title":_vm.title,"headers":_vm.headers,"field":'CREATED_AT',"filename":'Mouvements',"sheetname":'Mouvements',"showedit":false,"TypeScope":_vm.mvm_type,"VarScope":_vm.myBL == 'n' &&
          (_vm.$store.state.auth.includes('05021') || _vm.$store.state.isadmin)
            ? 1
            : 0,"btn1":_vm.mvm_type == 3 &&
          _vm.myBL == 'n' &&
          (_vm.$store.state.auth.includes('05021') || _vm.$store.state.isadmin)
            ? { text: 'Composer BE', icon: 'mdi-file-document-edit' }
            : {},"Add":_vm.editer,"del":_vm.editer && _vm.mvm_type == 5,"list_options":true,"search_elm":_vm.filtre},on:{"open":_vm.NewMvmForm,"rowselect":_vm.MvmChange,"click1":_vm.new_bon}})],1),(_vm.edition)?_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}}):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[_c('mvmprint',{key:_vm.keymvm,attrs:{"mvm":_vm.mvme,"mvm_type":_vm.mvm_type,"dossiers_list":_vm.dossiers_list,"mvms_type":_vm.mvms_type,"open_orders":_vm.open_orders,"units":_vm.units,"adress_list":_vm.adress_list,"boxs":_vm.boxs,"editer":_vm.editer,"valider":_vm.valider,"solder":_vm.solder,"encours_list":_vm.encours_list,"agents":_vm.agents,"articles_fab":_vm.articles_fab,"edition":_vm.edition,"attributes":_vm.attributes,"alldemandes":_vm.alldemandes,"data_loading":_vm.data_load,"ipg":_vm.options.itemsPerPage},on:{"change":_vm.DataUpdate,"delete":_vm.DataUpdate,"cancel":function($event){_vm.mvme = _vm.selitem},"edition":_vm.edit_change,"refresh":_vm.get_data,"options":_vm.get_option}})],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.snackbar_timeout,"top":"","color":_vm.snackbar_color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")]),_c('formscinder',{key:_vm.frml,attrs:{"showform":!_vm.isListClose},on:{"close":_vm.close_form}}),[_c('confirmdialog',{ref:"confirm"})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }